export const clientNameFooter = "Liana Linhares Yoga";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "Liana Linhares Yoga";
export const nomeFornecedor = "Liana";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://instagram.com/lianalinhares?igshid=MzRlODBiNWFlZA==",
    profile: "@lianalinhares"
  },
  {
    type: "material-community",
    name: "youtube",
    link: "https://www.youtube.com/@lianalinhares6600",
  },

  {
    type: "material-community",
    name: "whatsapp",
    link: "https://wa.me/61450706657",
    number: "+61 450 706 657"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://wa.me/61450706657",
  },
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];